<div [formGroup]="parentForm" novalidate>
    <input
        #descriptionInput
        name="description"
        [class]="inputClass"
        [ngClass]="{ 'ng-invalid': invalid }"
        [placeholder]="placeholder"
        [readonly]="readonly"
        type="text"
        formControlName="description"
        pInputText
        (change)="change.next($event)"
        (keyup)="keyUp.next($event)"
        (keyup.enter)="descriptionInput.blur()"
    />
</div>
