import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { TimeEntryDescriptionComponent } from "./components/time-entry-description/time-entry-description.component";
import { DropdownModule } from "primeng/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { UtilsModule } from "./utils/utils.module";
import { CalendarModule } from "primeng/calendar";
import { WorkdayProgressComponent } from "./components/workday-progress/workday-progress.component";
import { CheckboxModule } from "primeng/checkbox";
import { ToggleButtonModule } from "primeng/togglebutton";
import { TimeEntryRangePickerComponent } from "./components/time-entry-range-picker/time-entry-range-picker.component";
import { DateTimePickerComponent } from "./components/date-time-picker/date-time-picker.component";
import { CurrencyMaskDirective } from "./directives/currency-mask.directive";
import { InfiniteTableComponent } from "./components/infinite-table/infinite-table.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TableModule } from "primeng/table";
import { AssignedProjectsComponent } from "./components/assigned-projects/assigned-projects.component";
import { SelectProjectsModalComponent } from "./components/assigned-projects/select-projects-modal/select-projects-modal.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TimerFieldDetailsComponent } from "./components/timer-field-details/timer-field-details.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import { ColumnDaterangeFilterComponent } from "./components/column-daterange-filter/column-daterange-filter.component";
import { ColumnAmountrangeFilterComponent } from "./components/column-amountrange-filter/column-amountrange-filter.component";
import { InputNumberModule } from "primeng/inputnumber";
import { ColumnListFilterComponent } from "./components/column-list-filter/column-list-filter.component";
import { MultiSelectModule } from "primeng/multiselect";
import { AssignedServicesComponent } from "./components/assigned-services/assigned-services.component";
import { AssignedUsersComponent } from "./components/assigned-users/assigned-users.component";
import { PlayPauseStopButtonComponent } from "./components/play-pause-stop-button/play-pause-stop-button.component";
import { InfoTooltipComponent } from "./components/info-tooltip/info-tooltip.component";
import { TooltipModule } from "primeng/tooltip";
import { TextFilterComponent } from "./components/text-filter/text-filter.component";
import { AutoFocusDropdownFilterResultsDirective } from "./directives/auto-focus-dropdown-filter-results.directive";

@NgModule({
    declarations: [
        DateTimePickerComponent,
        TimeEntryDescriptionComponent,
        WorkdayProgressComponent,
        TimeEntryRangePickerComponent,
        CurrencyMaskDirective,
        InfiniteTableComponent,
        AssignedProjectsComponent,
        SelectProjectsModalComponent,
        TimerFieldDetailsComponent,
        ColumnDaterangeFilterComponent,
        ColumnAmountrangeFilterComponent,
        ColumnListFilterComponent,
        AssignedServicesComponent,
        AssignedUsersComponent,
        PlayPauseStopButtonComponent,
        InfoTooltipComponent,
        TextFilterComponent,
        AutoFocusDropdownFilterResultsDirective
    ],
    imports: [
        CommonModule,
        FormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        AutoCompleteModule,
        DropdownModule,
        ModalModule,
        UtilsModule,
        CalendarModule,
        CheckboxModule,
        ToggleButtonModule,
        InfiniteScrollModule,
        TableModule,
        InputNumberModule,
        BsDropdownModule.forRoot(),
        InlineSVGModule,
        MultiSelectModule,
        TooltipModule
    ],
    exports: [
        DateTimePickerComponent,
        TimeEntryDescriptionComponent,
        WorkdayProgressComponent,
        TimeEntryRangePickerComponent,
        CurrencyMaskDirective,
        InfiniteTableComponent,
        AssignedProjectsComponent,
        TimerFieldDetailsComponent,
        ColumnDaterangeFilterComponent,
        ColumnAmountrangeFilterComponent,
        ColumnListFilterComponent,
        AssignedServicesComponent,
        AssignedUsersComponent,
        PlayPauseStopButtonComponent,
        InfoTooltipComponent,
        TextFilterComponent,
        AutoFocusDropdownFilterResultsDirective
    ]
})
export class SharedModule {}
