<div class="progress">
  <div
    *ngFor="let entry of workDay.timeEntries"
    class="progress-bar"
    role="progressbar"
    [ngStyle]="{
      'width.%': (entry.durationInSeconds / dayDurationInSeconds) * 100,
      'margin-left.%': ((entry.startTime | secondsOffsetFromDay: dayStartInSeconds) / dayDurationInSeconds) * 100
    }"
    [style.--color-code]="entry.color ? entry.color : null"
    [attr.aria-valuenow]="entry.startTime | secondsOffsetFromDay: dayStartInSeconds"
    aria-valuemin="0"
    [attr.aria-valuemax]="dayDurationInSeconds"
  >
    <div class="progress-inner"></div>
  </div>
</div>
<div class="hourstamps mb-4">
  <span [ngClass]="{'font-weight-bold': hourStamp.label==='12PM'}" [ngStyle]="{'left.%':hourStamp.position}" *ngFor="let hourStamp of hourStamps">{{hourStamp.label}}</span>
</div>
