import { PermissionCheckerService, RefreshTokenService } from "abp-ng2-module";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { Observable } from "rxjs/internal/Observable";
import { of, Subject } from "rxjs";

@Injectable()
export class AppRouteGuard {
    constructor(
        private permissionChecker: PermissionCheckerService,
        private router: Router,
        private sessionService: AppSessionService,
        private refreshTokenService: RefreshTokenService
    ) {}

    canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        if (UrlHelper.isInstallUrl(location.href)) {
            return of(true);
        }

        if (!this.sessionService.user) {
            let sessionObservable = new Subject<any>();

            this.refreshTokenService.tryAuthWithRefreshToken().subscribe(
                (authResult: boolean) => {
                    if (authResult) {
                        sessionObservable.next(true);
                        sessionObservable.complete();
                        location.reload();
                    } else {
                        sessionObservable.next(false);
                        sessionObservable.complete();
                        this.router.navigate(["/account/login"], { queryParams: { returnUrl: state.url } });
                    }
                },
                (error) => {
                    sessionObservable.next(false);
                    sessionObservable.complete();
                    this.router.navigate(["/account/login"], { queryParams: { returnUrl: state.url } });
                }
            );
            return sessionObservable;
        }

        if (this.sessionService.tenant?.adminShouldCompleteTenantSetup) {
            this.router.navigate(["/account/complete-tenant-setup"]);
            return of(true);
        }

        if (!data || !data["permission"]) {
            return of(true);
        }

        if (this.permissionChecker.isGranted(data["permission"])) {
            return of(true);
        }

        this.router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {
        if (!this.sessionService.user) {
            return "/account/login";
        }

        if (this.permissionChecker.isGranted("Pages.Administration.Host.Dashboard")) {
            return "/app/admin/hostDashboard";
        }

        if (this.permissionChecker.isGranted("Pages.Tenant.Dashboard")) {
            return "/app/main/dashboard";
        }

        if (this.permissionChecker.isGranted("Pages.Tenants")) {
            return "/app/admin/tenants";
        }

        if (this.permissionChecker.isGranted("Pages.Administration.Users")) {
            return "/app/admin/users";
        }

        return "/app/notifications";
    }
}
