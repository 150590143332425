<div class="play-pause-stop-wrap" [ngClass]="{ small: smallIcons }">
    <div
        class="play-pause-stop position-absolute overflow-hidden"
        [ngClass]="{ 'is-running': isRunning, 'has-hover': enableHover }"
    >
        <div class="box box-play" (click)="playClick.emit()"></div>
        <div class="box box-stop" (click)="stopClick.emit()"></div>
        <div class="box box-pause"></div>
        <div class="box box-cancel" (click)="cancelClick.emit()"></div>
    </div>
</div>
