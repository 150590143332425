import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { merge, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from "rxjs/operators";
import { DescriptionParseResult, DescriptionService } from "@timer/services/description.service";

export interface DescriptionForm {
    description: FormControl<string>;
}

@Component({
    selector: "app-time-entry-description",
    templateUrl: "./time-entry-description.component.html",
    styleUrls: ["./time-entry-description.component.scss"]
})
export class TimeEntryDescriptionComponent implements OnInit, OnDestroy {
    @ViewChild("descriptionInput", { static: true }) descriptionInput!: ElementRef;

    @Input() autoFocus = false;
    @Input() inputClass = "";
    @Input() invalid = false;
    @Input() placeholder = "";
    @Input() readonly = false;
    @Input() parentForm!: FormGroup<DescriptionForm>;

    @Output() parseComplete = new EventEmitter<DescriptionParseResult | null>();
    @Output() parseStart = new EventEmitter<void>();

    readonly keyUp = new Subject<KeyboardEvent>();
    readonly change = new Subject<InputEvent>();

    get descriptionControl(): FormControl {
        return this.parentForm.controls.description;
    }

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(private descriptionService: DescriptionService) {}

    ngOnInit(): void {
        if (this.autoFocus) {
            this.descriptionInput.nativeElement.focus();
        }

        const keyUpTimeout$ = this.keyUp.pipe(
            debounceTime(1000),
            map((e): string => (e.target as HTMLInputElement).value)
        );
        const keyUpWordbreak$ = this.keyUp.pipe(
            map((e): string => (e.target as HTMLInputElement).value),
            filter((val) => /[ \f\t\v:;]$/.test(val))
        );
        const change$ = this.change.pipe(map((e): string => (e.target as HTMLInputElement).value));
        const controlCleared$ = this.descriptionControl.valueChanges.pipe(filter((val) => !val));

        merge(keyUpTimeout$, keyUpWordbreak$, change$, controlCleared$)
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                distinctUntilChanged(),
                switchMap((desc) => {
                    this.parseStart.emit();
                    return this.descriptionService.parseDescription(desc).pipe(catchError(() => of(null)));
                })
            )
            .subscribe({
                next: (result) => {
                    this.parseComplete.emit(result);
                },
                error: (error) => abp.notify.error(error)
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }
}
