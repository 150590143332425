<div class="row" [formGroup]="voqServicesForm">
    <h4 class="mb-5">Services</h4>
    <p-table
        #voqServicesTable
        [rowHover]="true"
        [value]="voqServicesControl?.controls"
        formArrayName="voqServices"
        dataKey="controls.rowId.value"
        editMode="row"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th class="text-center" *ngIf="showSetDefault">
                    Default Service
                    <app-info-tooltip
                        pTooltip="Time entries will automatically select the default service for this project."
                    ></app-info-tooltip>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData" [formGroupName]="ri">
                <td>
                    <p-dropdown
                        *ngIf="editing; else serviceNameLabel"
                        formControlName="service"
                        id="service"
                        name="service"
                        appendTo="body"
                        [options]="getFilteredVoqServices(rowData.controls.service.value?.id)"
                        optionLabel="name"
                        placeholder="Choose a service"
                    ></p-dropdown>
                    <ng-template #serviceNameLabel>
                        {{ rowData.controls.service.value?.name }}
                        <span *ngIf="rowData.controls.isDefault.value" [title]="l('Default')" class="text-secondary">
                            *
                        </span>
                    </ng-template>
                </td>
                <td class="text-center" *ngIf="showSetDefault">
                    <input
                        type="radio"
                        class="p-radio"
                        name="isDefault"
                        [value]="true"
                        [checked]="rowData.controls.service.value?.isDefault?.value"
                        formControlName="isDefault"
                        (change)="onDefaultServiceChanged(rowData)"
                    />
                </td>
                <td class="text-end">
                    <button
                        *ngIf="!isUpdatingServices"
                        type="button"
                        pButton
                        [title]="
                            assignedVoqServices.length <= 1 ? l('AtLeastOneServiceRequired') : l('RemoveFromProject')
                        "
                        [disabled]="assignedVoqServices.length <= 1 || isDisabled"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text"
                        (click)="deleteVoqService(rowData)"
                    ></button>
                    <button
                        *ngIf="editing"
                        type="button"
                        pButton
                        icon="pi pi-check"
                        class="p-button-rounded p-button-text p-button-success"
                        (click)="addVoqService(rowData)"
                    ></button>
                    <button
                        *ngIf="editing"
                        type="button"
                        pButton
                        pCancelEditableRow
                        icon="pi pi-times"
                        class="p-button-rounded p-button-text p-button-danger"
                        (click)="cancelAddEditService(rowData)"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="4" class="text-center">
                    <button
                        class="btn btn-link text-decoration-underline"
                        type="button"
                        (click)="prepNewServiceRow()"
                        [disabled]="assignServicesButtonIsDisabled"
                    >
                        Assign Service
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
