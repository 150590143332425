<div class="wrapper date-time-control" [formGroup]="datePickerForm">
    <input
        #timeInput
        formControlName="time"
        type="time"
        [step]="displaySeconds ? 1 : 60"
        name="time"
        [ngClass]="inputClass"
        placeholder="--:--"
        (focus)="onTouched(); inputFocusChange.emit()"
        (blur)="inputBlur.emit()"
        (keyup.enter)="timeInput.blur()"
        (keyup.esc)="inputKeyupEscape.emit(); timeInput.blur()"
    />
    <p-calendar
        formControlName="date"
        appendTo="body"
        [showIcon]="true"
        [styleClass]="displayDate ? '' : 'icon-only'"
        hideOnDateTimeSelect="false"
        dateFormat="mm/dd"
        (onShow)="onTouched(); inputFocusChange.emit()"
        (onClose)="inputBlur.emit()"
    ></p-calendar>
</div>
