/* eslint-disable brace-style */
import { DOCUMENT } from "@angular/common";
import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    forwardRef
} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators
} from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DurationUtils } from "@shared/helpers/duration-utils";
import { DateTime } from "luxon";
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    merge,
    takeUntil
} from "rxjs";
import { TimerService } from "@timer/services/timer.service";

interface TimeRangeForm {
    startTime: FormControl<Date | null>;
    stopTime: FormControl<Date | null>;
    duration: FormControl<string | null>;
}

export interface TimeRange {
    startTime?: Date | null;
    stopTime?: Date | null;
}

@Component({
    selector: "app-time-entry-range-picker",
    templateUrl: "./time-entry-range-picker.component.html",
    styleUrls: ["./time-entry-range-picker.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TimeEntryRangePickerComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => TimeEntryRangePickerComponent)
        }
    ]
})
export class TimeEntryRangePickerComponent
    extends AppComponentBase
    implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator
{
    @ViewChild("durationInput", { read: ElementRef }) durationInput!: ElementRef<HTMLInputElement>;

    @Input() formControlName!: string;
    @Input() isRunningTimer = false;
    @Input() saveFailed = false;
    @Input() showEditDetails = false;
    @Input() showExtendedForm = false;
    @Input() workday!: Date;
    @Output() editModeEnabled = new EventEmitter();

    onChange: (_: TimeRange | null) => void = () => {};
    onTouched: () => void = () => {};
    protected durationInSeconds = 0;
    private originalValues!: { startTime: Date | null; stopTime: Date | null; duration: string | null };

    protected readonly timeRangeForm: FormGroup<TimeRangeForm>;

    protected get startTimeControl(): FormControl<Date | null> {
        return this.timeRangeForm.controls.startTime;
    }
    protected get stopTimeControl(): FormControl<Date | null> {
        return this.timeRangeForm.controls.stopTime;
    }
    protected get durationControl(): FormControl<string | null> {
        return this.timeRangeForm.controls.duration;
    }

    protected readonly unitOfWorkActive$ = new BehaviorSubject<boolean>(false);
    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private formBuilder: FormBuilder,
        public timerService: TimerService
    ) {
        super(injector);
        this.timeRangeForm = this.formBuilder.group<TimeRangeForm>({
            startTime: new FormControl<Date | null>(null, Validators.required),
            stopTime: new FormControl<Date | null>(null, Validators.required),
            duration: new FormControl<string | null>({ value: null, disabled: true })
        });
    }

    ngOnInit(): void {
        if (!this.isRunningTimer) {
            this.timeRangeForm.setValidators([checkIfEndDateAfterStartDate]);
        }

        this.subscribeToFormChanges();
        if (this.isRunningTimer) {
            this.subscribeToTimerEvents();
        }
    }

    private subscribeToFormChanges(): void {
        this.timeRangeForm.statusChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: (status) => {
                if (status === "INVALID") {
                    this.onChange(null);
                }
            }
        });

        this.durationControl.valueChanges
            .pipe(takeUntil(this.unsubscribeSubject$), distinctUntilChanged(), debounceTime(500))
            .subscribe({
                next: (duration) => {
                    if (duration === null) {
                        return;
                    }
                    if (!this.startTimeControl.value && !this.stopTimeControl.value) {
                        return;
                    }

                    const newDuration = DurationUtils.parseDuration(duration);

                    if (this.isRunningTimer || !this.startTimeControl.value) {
                        // TODO: Now is used whenever stopTime is null, ensure this doesn't break anything as it was just passing null to DateTime.fromJSDate before
                        const stopTime =
                            this.isRunningTimer || this.stopTimeControl.value === null
                                ? DateTime.now()
                                : DateTime.fromJSDate(this.stopTimeControl.value);
                        this.timeRangeForm.patchValue({
                            startTime: stopTime.minus(newDuration).toJSDate()
                        });
                    } else {
                        const startTime = DateTime.fromJSDate(this.startTimeControl.value);
                        this.timeRangeForm.patchValue({
                            stopTime: startTime.plus(newDuration).toJSDate()
                        });
                    }

                    this.durationInSeconds = newDuration.as("seconds");
                }
            });

        this.startTimeControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: (startTime) => {
                if (!this.isRunningTimer && this.stopTimeControl.value) {
                    this.timeRangeForm.patchValue(
                        {
                            stopTime: DateTime.fromJSDate(this.stopTimeControl.value).set({ second: 0 }).toJSDate()
                        },
                        { emitEvent: false }
                    );
                }
                this.snapshotDuration();
                this.setDurationDisabled(startTime, this.stopTimeControl.value);
            }
        });

        this.stopTimeControl.valueChanges.pipe(takeUntil(this.unsubscribeSubject$)).subscribe({
            next: (stopTime) => {
                if (this.startTimeControl.value) {
                    this.timeRangeForm.patchValue(
                        {
                            startTime: DateTime.fromJSDate(this.startTimeControl.value).set({ second: 0 }).toJSDate()
                        },
                        { emitEvent: false }
                    );
                }
                this.snapshotDuration();
                this.setDurationDisabled(this.startTimeControl.value, stopTime);
            }
        });

        combineLatest([this.timeRangeForm.valueChanges, this.unitOfWorkActive$])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([, uowActive]) => this.timeRangeForm.valid && !uowActive)
            )
            .subscribe({
                next: ([form]) => {
                    this.onChange({
                        startTime: form.startTime,
                        stopTime: form.stopTime
                    });
                }
            });
    }

    private subscribeToTimerEvents(): void {
        const detachRunningTimer$ = merge(this.timerService.timerStopped$, this.unsubscribeSubject$);

        combineLatest([this.timerService.secondsElapsed$, this.unitOfWorkActive$])
            .pipe(
                takeUntil(detachRunningTimer$),
                filter(([, uowActive]) => !uowActive)
            )
            .subscribe({
                next: () => {
                    this.snapshotDuration();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["showEditDetails"]?.currentValue === true) {
            this.snapshotDuration();
        }

        if (changes["isRunningTimer"]?.currentValue === false) {
            this.timeRangeForm.setValidators([checkIfEndDateAfterStartDate]);
        }
    }

    override ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
        super.ngOnDestroy();
    }

    writeValue(value: TimeRange): void {
        this.timeRangeForm.patchValue(
            {
                startTime: value?.startTime,
                stopTime: value?.stopTime
            },
            { emitEvent: false }
        );
        this.snapshotDuration();
        this.snapshotFormValues();
        this.setDurationDisabled(value?.startTime, value?.stopTime);
    }

    registerOnChange(onChange: (_: TimeRange | null) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.timeRangeForm.disable();
        } else {
            this.timeRangeForm.enable();
            this.setDurationDisabled(this.startTimeControl.value, this.stopTimeControl.value);
        }
    }

    validate(): ValidationErrors | null {
        if (this.timeRangeForm.valid) {
            return null;
        }

        const errors: ValidationErrors = {};
        if (this.startTimeControl.errors) {
            errors[this.formControlName] = this.startTimeControl.errors;
        } else if (this.stopTimeControl.errors) {
            errors[this.formControlName] = this.stopTimeControl.errors;
        }

        return errors;
    }

    resetValues(): void {
        this.timeRangeForm.patchValue({
            startTime: this.originalValues?.startTime,
            stopTime: this.originalValues?.stopTime,
            duration: this.originalValues?.duration
        });
        this.timeRangeForm.markAsPristine();
        this.timeRangeForm.markAsUntouched();
    }

    selectDurationInput(event: MouseEvent): void {
        event.stopPropagation();
        this.showEditDetails = true;
        this.editModeEnabled.emit(true);
        setTimeout(() => {
            this.unitOfWorkActive$.next(true);
            this.durationInput.nativeElement.select();
            this.onTouched();
        }, 0);
    }

    private snapshotDuration(): void {
        if (this.durationInput && this.durationInput.nativeElement === this.document.activeElement) {
            return;
        }

        const startTime = !this.startTimeControl.value ? null : DateTime.fromJSDate(this.startTimeControl.value);
        let stopTime: DateTime | null;
        if (this.isRunningTimer) {
            stopTime = DateTime.now();
        } else if (!this.stopTimeControl.value) {
            stopTime = null;
        } else {
            stopTime = DateTime.fromJSDate(this.stopTimeControl.value);
        }

        let durationInSeconds = 0;
        if (startTime?.isValid && stopTime?.isValid) {
            durationInSeconds = stopTime.diff(startTime).as("seconds");
        }

        this.durationInSeconds = durationInSeconds;
        this.timeRangeForm.patchValue(
            {
                duration: DurationUtils.fromSeconds(durationInSeconds)
            },
            {
                emitEvent: false
            }
        );
    }

    private setDurationDisabled(startTime?: Date | null, stopTime?: Date | null): void {
        const startTimeIsValid = startTime && DateTime.fromJSDate(startTime).isValid;
        const stopTimeIsValid = stopTime && DateTime.fromJSDate(stopTime).isValid;
        if (startTimeIsValid || stopTimeIsValid) {
            this.durationControl.enable({ emitEvent: false });
        } else {
            this.durationControl.disable({ emitEvent: false });
        }
    }

    private snapshotFormValues(): void {
        this.originalValues = {
            startTime: this.startTimeControl.value ? new Date(this.startTimeControl.value) : null,
            stopTime: this.stopTimeControl.value ? new Date(this.stopTimeControl.value) : null,
            duration: this.durationControl.value
        };
    }
}

function checkIfEndDateAfterStartDate(control: AbstractControl): ValidationErrors | null {
    //safety check
    if (!control.get("startTime")?.value || !control.get("stopTime")?.value) {
        return null;
    }
    const durationControl = control.get("duration");
    const startTime = control.get("startTime")?.value;
    const stopTime = control.get("stopTime")?.value;
    if (startTime >= stopTime) {
        durationControl?.setErrors({ invalidDuration: true });
        return { invalidDuration: true };
    } else {
        durationControl?.setErrors(null);
        return null;
    }
}
