import { TimeEntryModel } from "@shared/service-proxies/service-proxies";
import { DateTime } from "luxon";

export class WorkDayDto {
    date: DateTime;
    totalDurationInSeconds?: number;
    isToday?: boolean;

    private _timeEntries: TimeEntryModel[] = [];
    readonly timeEntries: ReadonlyArray<TimeEntryModel> = this._timeEntries;

    constructor(date: DateTime, timeEntries: TimeEntryModel[]) {
        this.date = date.startOf("day");
        this._timeEntries.push(...timeEntries);
        this.calculateTotalWorkDayDuration();
        this.sortTimeEntries();
        this.calculateIsToday();
    }

    upsertTimeEntry(timeEntry: TimeEntryModel): void {
        const index = this.getTimeEntryIndex(timeEntry);
        if (index < 0) {
            this._timeEntries.unshift(timeEntry);
        } else {
            this._timeEntries[index]?.init(timeEntry);
        }

        this.calculateTotalWorkDayDuration();
        this.sortTimeEntries();
    }

    removeTimeEntry(timeEntry: TimeEntryModel): void {
        const index = this.getTimeEntryIndex(timeEntry);
        if (index < 0) {
            return;
        }

        this._timeEntries.splice(index, 1);
        this.calculateTotalWorkDayDuration();
    }

    calculateTotalWorkDayDuration(): void {
        if (!this.timeEntries.length) {
            this.totalDurationInSeconds = 0;
            return;
        }

        this.totalDurationInSeconds = this.timeEntries.map((te) => te.durationInSeconds).reduce((a, b) => a + b, 0);
    }

    private calculateIsToday(): void {
        this.isToday = this.date.hasSame(DateTime.now(), "day");
    }

    private getTimeEntryIndex(timeEntry: TimeEntryModel): number {
        return this._timeEntries.findIndex((te) => te.id === timeEntry.id);
    }

    private sortTimeEntries(): void {
        this._timeEntries.sort((a, b) => b.startTime.valueOf() - a.startTime.valueOf());
    }
}
