<div class="timer-field-details-wrapper" [formGroup]="parentForm">
    <div class="timer-field-details flex-grow-1">
        <div class="row" [ngClass]="stackFields ? 'gy-8' : 'mb-4 gy-4'">
            <div [ngClass]="stackFields ? 'col-12' : 'col-6 col-lg-4'">
                <label for="Project" *ngIf="stackFields">{{ l("Project") }}*</label>
                <p-dropdown
                    formControlName="project"
                    [styleClass]="stackFields ? '' : 'outline'"
                    dropdownIcon="pi pi-chevron-down chevron-down"
                    [panelStyleClass]="stackFields ? '' : 'outline-panel'"
                    id="Project"
                    name="Project"
                    [options]="projects$ | async"
                    optionLabel="name"
                    dataKey="id"
                    [filter]="true"
                    filterBy="filterField"
                    placeholder="Project"
                    [showClear]="true"
                    [ngClass]="{ 'ng-invalid': saveFailed }"
                >
                    <ng-template let-item pTemplate="selectedItem">
                        {{ item?.name }}
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div>{{ item?.name }}</div>
                        <small class="text-light">{{ item?.clientName }}</small>
                    </ng-template>
                </p-dropdown>
                <div *ngIf="projectControl.invalid" class="text-danger mt-1 ps-3">Project is required</div>
            </div>
            <div [ngClass]="stackFields ? 'col-12' : 'col-6 col-lg-3'">
                <label for="VoqService" *ngIf="stackFields">{{ l("VoqService") }}*</label>
                <p-dropdown
                    formControlName="voqService"
                    [styleClass]="stackFields ? '' : 'outline'"
                    dropdownIcon="pi pi-chevron-down chevron-down"
                    [panelStyleClass]="stackFields ? '' : 'outline-panel'"
                    id="VoqService"
                    name="VoqService"
                    [options]="voqServices$ | async"
                    optionLabel="name"
                    datakey="id"
                    placeholder="Service"
                    [showClear]="true"
                    [filter]="true"
                    filterBy="name"
                    [ngClass]="{ 'ng-invalid': saveFailed }"
                ></p-dropdown>
                <div *ngIf="voqServiceControl.invalid" class="text-danger mt-1 ps-3">Service is required</div>
            </div>
            <div [ngClass]="stackFields ? 'col' : 'col-6 col-lg-4'">
                <label for="Issue" *ngIf="stackFields">{{ l("Task") }}</label>
                <p-dropdown
                    id="Issue"
                    name="Issue"
                    formControlName="issue"
                    [styleClass]="stackFields ? '' : 'outline'"
                    dropdownIcon="pi pi-chevron-down chevron-down"
                    [panelStyleClass]="stackFields ? '' : 'outline-panel'"
                    [options]="issues$ | async"
                    dataKey="issueKey"
                    (onFilter)="filterIssues$.next($event?.filter ?? undefined)"
                    (onChange)="onIssueChange($event?.value)"
                    (onShow)="updateIssues()"
                    [showClear]="true"
                    [filter]="true"
                    [resetFilterOnHide]="true"
                    filterBy="issueKey"
                    [placeholder]="l('Task')"
                    [ngClass]="{ 'ng-invalid': saveFailed }"
                >
                    <ng-template let-issue pTemplate="selectedItem">
                        {{ issue?.issueKey }}
                    </ng-template>
                    <ng-template let-issue pTemplate="item">
                        <div>{{ issue?.issueKey }}: {{ issue?.title }}</div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div
                class="col-auto d-flex align-items-center"
                *ngIf="
                    issueControl?.value?.issueKey &&
                        issueControl?.value?.jiraIssueId &&
                        issuesService.jiraIntegrationUrl | async
                "
            >
                <a
                    [href]="(issuesService.jiraIntegrationUrl | async) + '/browse/' + issueControl?.value?.issueKey"
                    target="_blank"
                >
                    <span [inlineSVG]="'/assets/metronic/themes/voqaratheme1/css/timer-share.svg'"></span>
                </a>
            </div>
            <div
                class="d-flex flex-nowrap align-items-center"
                [ngClass]="{ 'd-none': !selectedProject?.isBillable, 'col-auto': !stackFields, 'col-12': stackFields }"
            >
                <p-checkbox
                    formControlName="billable"
                    styleClass="field-checkbox"
                    checkboxIcon="check-icon"
                    labelStyleClass="checkbox-label"
                    name="isBillable"
                    id="isBillable"
                    inputId="isBillable"
                    [binary]="true"
                    [ngClass]="{ 'ng-invalid': saveFailed }"
                ></p-checkbox>
                <label for="isBillable" class="billable-checkbox">Billable</label>
            </div>
        </div>
    </div>
</div>
