<ng-container [formGroup]="timeRangeForm">
    <div class="start-date">
        <label for="startTime" *ngIf="showExtendedForm">{{ l("StartTime") }}*</label>
        <app-date-time-picker
            [class.edit-mode]="showEditDetails"
            formControlName="startTime"
            [defaultDate]="workday"
            [displayDate]="showExtendedForm"
            [inputClass]="{ 'form-control': showExtendedForm, 'ng-invalid': saveFailed }"
            (inputFocusChange)="unitOfWorkActive$.next(true); onTouched()"
            (inputBlur)="unitOfWorkActive$.next(false)"
            (inputKeyupEscape)="resetValues()"
        ></app-date-time-picker>
    </div>
    <div>
        <label *ngIf="showExtendedForm">&nbsp;</label>
        <div class="p4 mx-1">-</div>
    </div>
    <div class="stop-date">
        <label for="stopTime" *ngIf="showExtendedForm">{{ l("StopTime") }}*</label>
        <ng-container *ngIf="!isRunningTimer">
            <app-date-time-picker
                [class.edit-mode]="showEditDetails"
                formControlName="stopTime"
                [defaultDate]="workday"
                [displayDate]="showExtendedForm"
                [inputClass]="{ 'form-control': showExtendedForm, 'ng-invalid': saveFailed }"
                (inputFocusChange)="unitOfWorkActive$.next(true); onTouched()"
                (inputBlur)="unitOfWorkActive$.next(false)"
                (inputKeyupEscape)="resetValues()"
            ></app-date-time-picker>
        </ng-container>
        <ng-container *ngIf="isRunningTimer">
            <span class="p4">Now</span>
        </ng-container>
    </div>
    <div class="duration">
        <label for="duration" *ngIf="showExtendedForm">{{ l("Duration") }}</label>
        <div
            class="p4 mx-2 text-nowrap"
            *ngIf="!showEditDetails; else durationInputTemplate"
            (click)="selectDurationInput($event)"
        >
            {{ durationInSeconds | timerDisplayFormat: "user" }}
        </div>
        <ng-template #durationInputTemplate>
            <input
                #durationInput
                [ngClass]="{
                    'form-control': showExtendedForm,
                    'progress-input ms-2 text-center': !showExtendedForm,
                    'ng-invalid': durationControl.dirty && saveFailed
                }"
                pInputText
                type="text"
                id="duration"
                formControlName="duration"
                (focus)="selectDurationInput($event)"
                (blur)="unitOfWorkActive$.next(false)"
                (keyup.enter)="durationInput.blur()"
                (keyup.esc)="resetValues(); durationInput.blur()"
            />
        </ng-template>
    </div>
</ng-container>
