import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { WorkDayDto } from "@shared/models/WorkDayDto";
import { DateTime, Duration } from "luxon";

//TODO: Deprecated, to be replaced by work-day-timeline
@Component({
    selector: "app-workday-progress",
    templateUrl: "./workday-progress.component.html",
    styleUrls: ["./workday-progress.component.scss"]
})
export class WorkdayProgressComponent implements OnInit, OnChanges {
    private static suggestedStartTimeInSeconds = Duration.fromObject({ hours: 8 }).as("seconds");
    private static suggestedEndTimeInSeconds = Duration.fromObject({ hours: 16 }).as("seconds");

    @Input() workDay!: WorkDayDto;

    dayStartInSeconds!: number;
    dayEndInSeconds!: number;
    dayDurationInSeconds!: number;
    hourStamps: Array<{ label: string; position: number }> = [];

    constructor() {}

    ngOnInit(): void {
        this.configureBookends();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workDay) {
            //TODO: workday is updated in WorkdayService.upsertTimeEntryOnWorkDay(), it might not be triggering change detection.
            this.configureBookends();
        }
    }

    private configureBookends(): void {
        if (!this.workDay?.timeEntries?.length) {
            return;
        }
        const startOfDay = DateTime.min(...this.workDay.timeEntries.map((te) => te.startTime));
        const endOfDay = DateTime.max(...this.workDay.timeEntries.map((te) => te.stopTime));
        const todayAtStart = startOfDay.startOf("day");
        const dayStartSeconds = startOfDay.diff(todayAtStart).as("seconds");
        const dayEndSeconds = endOfDay.diff(todayAtStart).as("seconds");
        this.dayStartInSeconds = Math.min(dayStartSeconds, WorkdayProgressComponent.suggestedStartTimeInSeconds);
        this.dayEndInSeconds = Math.max(dayEndSeconds, WorkdayProgressComponent.suggestedEndTimeInSeconds);
        this.dayDurationInSeconds = this.dayEndInSeconds - this.dayStartInSeconds;
        this.generateHourLabels(todayAtStart);
    }

    private generateHourLabels(todayAtStart: DateTime): void {
        this.hourStamps.length = 0;
        const wholeHoursInTheDay = Math.floor((this.dayEndInSeconds - this.dayStartInSeconds) / 3600);
        const startingWholeHour = Math.ceil(this.dayStartInSeconds / 3600);
        for (let i = 0; i <= wholeHoursInTheDay; i++) {
            const labelledDateTime = todayAtStart.plus({ hours: startingWholeHour + i });
            const label = labelledDateTime.toFormat("ha");

            const labelledDateTimeSeconds = labelledDateTime.diff(todayAtStart).as("seconds");
            const position = ((labelledDateTimeSeconds - this.dayStartInSeconds) / this.dayDurationInSeconds) * 100;

            this.hourStamps.push({ label, position });
        }
    }
}
